import React from "react"
import Layout from "@components/layout";

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const PrivacyPolicy = () => {
  // Seo情報設定
  const pathName = 'privacy-policy';
  const seoTag = SeoContents({ Path:pathName });

  return (
  <>
    <Layout>
      {seoTag}      
      <div> 
        {/* FV */}
        <div className="corp_h1">
          <h1 className="font-semibold ">プライバシーポリシー</h1>
        </div>
        <div>
          <div className="max-w-xl mx-auto  md:max-w-5xl px-6 mb-[60px] mt-[50px]">
            <h2 className="mb-2 font-semibold">プライバシーについて</h2>
            <p className="mb-5 text-[12px]">「株式会Enzine」(以下、「弊社」といいます)は、弊社の運営するウェブまたはモバイルサイト(以下、「運営サイト」といいます)をご利用の際にご提供いただくサービス利用者(以下、「ユーザ」といいます)の氏名、ご連絡先等の個人を特定しうる情報(以下、「個人情報」といいます) の重要性を認識し、以下の取り組みを実施して個人情報を慎重に取扱い、プライバシーの保護に努めております。</p>
            <h2 className="mb-2 font-semibold">個人情報の登録について</h2>
            <p className="mb-5 text-[12px]">運営サイトをご利用いただく場合、ユーザには必要に応じて、ご自身の個人情報を入力または登録していただくことがあります。</p>
            <h2 className="mb-2 font-semibold">個人情報の管理について</h2>
            <p className="mb-5 text-[12px]">運弊社は、ユーザに登録して頂いた個人情報を適切、厳重に管理し、ユーザの個人情報への不正なアクセスや情報の紛失、破壊、改ざんおよび漏洩等が起きぬよう、予防ならびに安全対策を講じております。<br/>
            なお、個人情報を保管するデータベースサーバは、24時間管理のセキュリティ設備のある、データセンターで厳重に管理されています。また、このサーバに保管されているデータへのアクセスは、弊社、または、弊社の契約する運用代行会社の一部の人間に厳しく制限されています。</p>
            
            <h2 className="mb-2 font-semibold">個人情報の利用について</h2>
            <p className="mb-5 text-[12px]">以下の何れかに該当する場合を除き、弊社が個人情報を利用することはありません。<br/>
            (1) ユーザの同意を得た場合、またはユーザの個人情報の確認の為、必要に応じてユーザの個人情報をお伺いする場合。<br/>
            (2) 弊社からメールマガジン等で、ユーザに情報を提供する場合。<br/>
            (3) 統計情報をサービス向上や合法的な目的のために使用する場合。<br/>
            (4) 弊社がサービスを提供する上で、ユーザへ必要かつ有益と思われるご連絡事項・情報を発信する場合。<br/>
            (5)個人データの管理業務を委託された場合。<br/>
            (6)封入、封緘、発送の業務を委託された場合。<br/>
            (7)ユーザからのお問い合わせに対応する場合。<br/>
            (8)インタビューの模様等を撮影した画像、音声をユーザの同意を得て公開する場合。</p>

            <h2 className="mb-2 font-semibold">個人情報の第三者への提供について</h2>
            <p className="mb-5 text-[12px]">以下の何れかに該当する場合を除き、弊社がユーザの個人情報を第三者に提供、開示等をすることはありません。<br/>
            (1) あらかじめ弊社と機密保持の契約を締結している会社、金融機関等に、弊社の提供するサービスに関連して、その準備、履行またはアフターサービス等に必要な範囲で提供・開示する場合。<br/>
            (2) 法令等により開示が求められる場合。<br/>
            (3) ユーザ及び公衆の生命、健康、財産などに重大な損害が発生することを防止する為(公共の利益のため)に必要であると考えられる場合。<br/>
            (4) ユーザの同意を得た場合。<br/>
            但し、ユーザの同意に基づき個人情報を提供する会社には、ユーザの個人情報を漏洩や再提供等しないよう、契約により義務づけ、適切な管理を実施させます。</p>
            
            <h2 className="mb-2 font-semibold">個人情報の第三者への提供について</h2>
            <p className="mb-5 text-[12px]">以下の何れかに該当する場合を除き、弊社がユーザの個人情報を第三者に提供、開示等をすることはありません。<br/>
            (1) あらかじめ弊社と機密保持の契約を締結している会社、金融機関等に、弊社の提供するサービスに関連して、その準備、履行またはアフターサービス等に必要な範囲で提供・開示する場合。<br/>
            (2) 法令等により開示が求められる場合。<br/>
            (3) ユーザ及び公衆の生命、健康、財産などに重大な損害が発生することを防止する為(公共の利益のため)に必要であると考えられる場合。<br/>
            (4) ユーザの同意を得た場合。<br/>
            但し、ユーザの同意に基づき個人情報を提供する会社には、ユーザの個人情報を漏洩や再提供等しないよう、契約により義務づけ、適切な管理を実施させます。</p>
            
            <h2 className="mb-2 font-semibold">クッキーについて</h2>
            <p className="mb-5 text-[12px]">以下の何れかに該当する場合を除き、弊社がユーザの個人情報を第三者に提供、開示等をすることはありません。<br/>
            運営サイトでは、ユーザに一層便利にご利用いただくため、又、閲覧履歴を採取するために「クッキー」という技術を使っているページがあります。<br/>
            「クッキー」は、ウェブサイトからユーザのコンピュータを識別する技術です。<br/>
            ユーザは、ブラウザの設定によりクッキーの受取りを拒否したり、クッキーを受け取ったとき警告を表示させたりすることができます。<br/>
            ユーザがクッキーの受取りを拒否された場合も運営サイトをご利用いただけますが一部機能がご利用になれない場合があります。</p>
            
            <h2 className="mb-2 font-semibold">閲覧履歴の採取について</h2>
            <p className="mb-5 text-[12px]">運営サイトでは、ユーザの閲覧履歴情報を収集しております。収集される情報は、運営サイトの利用状況の把握およびサービスの企画、向上のための集計、分析に用い、それ以外に利用することはありません。</p>
            <h2 className="mb-2 font-semibold">トラフィックデータ収集</h2>
            <p className="mb-5 text-[12px]">運営サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br/>このツールではトラフィックデータ収集のためにクッキー情報を使用しております。<br/>トラフィックデータは匿名で収集されており、個人を特定するものではございません。また、クッキーを無効にすることで収集を拒否することが可能ですので、お使いのブラウザの設定をご確認ください。</p>
            <h2 className="mb-2 font-semibold">個人情報収集の目的</h2>
            <p className="mb-5 text-[12px]">個人情報は、サービスの向上やユーザからのお問い合わせ、商品の発送等に対応することを目的として、必要な範囲内で収集しております。</p>
            <h2 className="mb-2 font-semibold">変更及び修正</h2>
            <p className="mb-5 text-[12px]">本プライバシーポリシーの内容は､サービス内容や技術動向の変化、法令の改正等に従って予告なく変更及び修正されることがあります｡</p>
            <h2 className="mb-2 font-semibold">ご意見、お問い合わせ等の受付窓口に関する事項</h2>
            <p className="mb-5 text-[12px]">当社の個人情報の取扱いに関するご意見、お問い合わせ等につきましては、下記の窓口にご連絡ください。<br/>
            なお、直接の来社によるお問い合わせにつきましてはお断りさせていただいておりますので、予めご了承ください。<br/>
            株式会社Enzine<br/>
            »お問い合わせフォーム</p>
            
            <h2 className="mb-2 font-semibold">その他</h2>
            <p className="mb-5 text-[12px]">（1）弊社が、ユーザから自己の情報について開示、確認、訂正、削除等を求められた場合は、合理的な期間内にこれに対応致します。<br/>
            （2）運営サイトは、運営サイトが保有する個人情報に関して適用される法令、規範等を遵守するとともに、個人情報の取扱いに関する取り組みを適宜見直し、改善していきます。<br/>
            （3）弊社は、運営サイトからリンクしている他のウェブサイトにおけるユーザの個人情報の安全確保については、責任を負うことはできません。<br/><br/>
            ユーザご自身にて、弊社以外のウェブサイトにおける個人情報についての取扱い基準等の内容を吟味し、取扱い基準等が定められていない場合には当該ウェブサイトの担当部署・担当者に直接確認されるなどして、ご自身の個人情報の安全を確認されるようお勧めいたします。</p>
          </div>
        </div>
      </div>
    </Layout> 
  </>
  )
}
export default PrivacyPolicy
